.changer-box {
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 35px;
   margin-left: 5px;
   width: fit-content;
   /* // border-radius: 12px;
   // overflow: hidden; */
}
.changer-box svg {
   cursor: pointer;
}
.changer-box-icon1,
.changer-box-icon2 {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 8px;
   gap: 8px;

   width: 36px;
   height: 40px;

   background: #f9fafb;

   border: 1px solid #e0e3e7;
   /* // border-radius: 0px 12px 12px 0px; */
}

.changer-box-icon2 {
   border-radius: 0px 12px 12px 0px;
}
.changer-box-icon1 {
   border-radius: 12px 0px 0px 12px;
}

.current-box {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 8px 16px;
   gap: 4px;

   width: 90px;
   height: 40px;

   background: #fafcfe;
   /* Text/40 */

   border: 1px solid #e0e3e7;
}
